<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="SPAC 업무요청">
            - 배정명단 必 첨부 ( 엑셀형식 : 이름, 이메일주소, 핸드폰번호 반드시 기입 )
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 제목</th>
                                <td colspan="3">
                                    <input type="text" v-model.trim="mod.info.title" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 분류</th>
                                <td>
                                    <carrot-category v-if="mod.is_loaded" ctype="spac" v-model="mod.info.ctype" class="w-200px"></carrot-category>
                                </td>
                                <th><span class="txt-red">*</span> 희망완료일</th>
                                <td>
                                    <carrot-date-picker v-if="mod.is_loaded" v-model="mod.info.complete_date" class="w-110px"></carrot-date-picker>
                                </td>
                            </tr>
                            <tr>
                                <th>중요도</th>
                                <td colspan="3">
                                    <select v-model="mod.info.priority" class="w-200px">
                                        <option value="1">낮음</option>
                                        <option value="2">보통</option>
                                        <option value="3">높음</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내용</th>
                                <td colspan="3">
                                    <CarrotEditor etype="spac" v-model="mod.info.contents" class="w-100per h-300px"></CarrotEditor>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부 #1</th>
                                <td>
                                    <a v-if="mod.file1_name" :href="mod.file1_url" class="btn-view" target="_blank">{{ mod.file1_name }}</a>
                                    <label v-if="mod.file1_name" class="ml-10"><input type="checkbox" v-model="mod.dchk1"> 삭제</label>
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부 #2</th>
                                <td>
                                    <a v-if="mod.file2_name" :href="mod.file2_url" class="btn-view" target="_blank">{{ mod.file2_name }}</a>
                                    <label v-if="mod.file2_name" class="ml-10"><input type="checkbox" v-model="mod.dchk2"> 삭제</label>
                                    <CarrotFileUploader ref="file2" class="mt-5"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부 #3</th>
                                <td>
                                    <a v-if="mod.file3_name" :href="mod.file3_url" class="btn-view" target="_blank">{{ mod.file3_name }}</a>
                                    <label v-if="mod.file3_name" class="ml-10"><input type="checkbox" v-model="mod.dchk3"> 삭제</label>
                                    <CarrotFileUploader ref="file3" class="mt-5"></CarrotFileUploader>
                                </td>
                            </tr>                        </tbody>
                    </table>
                    <button @click="mod.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="mod.doSubmit" class="btn-default float-right mt-30">저장</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotCategory from '@/components/common/CarrotCategory.vue'
import CarrotEditor from '@/components/common/CarrotEditor.vue'
import Swal from 'sweetalert2'


export default {
    layout:"BusinessManagement",
    components: {
        CarrotDatePicker,
        CarrotCategory,
        CarrotEditor
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast = useToast()

        const file1 = ref(null);
        const file2 = ref(null);
        const file3 = ref(null);

        const mod = reactive({
            is_loaded : false,

            idx : 0,

            info : {
                title : "",
                ctype : "",
                complete_date : "",
                priority      : 2,
                request_url   : "",
                contents      : "",
            },

            file1_name : "", file1_url : "",
            file2_name : "", file2_url : "",
            file3_name : "", file3_url : "",

            dchk1 : false, dchk2 : false, dchk3 : false, 

            doCancel: () => {
                router.go(-1);
            },

            doSearch: () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/businessmgr/SpacView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;
                        mod.is_loaded = true;

                        mod.file1_name = mod.info.file1_name;
                        mod.file1_url  = mod.info.file1_url;
                        mod.file2_name = mod.info.file2_name;
                        mod.file2_url  = mod.info.file2_url;
                        mod.file3_name = mod.info.file3_name;
                        mod.file3_url  = mod.info.file3_url;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit: () => {
                var formData = new FormData();
                formData.append("idx"  , mod.idx);
                formData.append("title", mod.info.title);
                formData.append("ctype", mod.info.ctype);
                formData.append("complete_date", mod.info.complete_date);
                formData.append("priority"     , mod.info.priority);
                formData.append("contents"     , mod.info.contents);

                if(file1.value.file.filedata!==null) formData.append("nfile1", file1.value.file.filedata);
                if(file2.value.file.filedata!==null) formData.append("nfile2", file2.value.file.filedata);
                if(file3.value.file.filedata!==null) formData.append("nfile3", file3.value.file.filedata);
                formData.append("dchk1", mod.dchk1==true?"Y":"N");
                formData.append("dchk2", mod.dchk2==true?"Y":"N");
                formData.append("dchk3", mod.dchk3==true?"Y":"N");

                if( !mod.info.title || !mod.info.ctype || !mod.info.complete_date || !mod.info.contents ){
                    Swal.fire({
                        title : "SPAC",
                        text  : "필수 항목을 입력해주세요."
                    });
                    return;
                }

                axios.post('/rest/businessmgr/SpacMod', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"BusinessManagement-SPACView-idx",
                            params : { 
                                idx:res.data.idx 
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });

        onMounted(() => {
            // Mounted
            mod.is_loaded = false;

            mod.idx = route.params.idx;
            if ( !mod.idx ) {
                router.go(-1);
            } 

            mod.doSearch();
        });

        return {mod, file1, file2, file3};
    }
}
</script>

<style lang="scss" scoped>
</style>